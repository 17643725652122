import React, { Fragment, useState, useRef } from "react";
import _ from "lodash";
import RegisterFields from "./RegisterFields";
import doctorFormFields from "./doctorFormFields";

export const DoctorEditForm = ({ onChange, initailValues, onSelect, node }) => {
  const inputRef = useRef({});

  const renderFields = () => {
    return _.map(
      doctorFormFields,
      ({ label, name, type, options, required }) => {
        return (
          <RegisterFields
            name={name}
            label={label}
            type={type}
            value={inputRef.current.name}
            options={options}
            required={required}
            initailValue={initailValues[name]}
            disabled={name === "id" ? true : false}
            onChange={onChange(name)}
            node={node}
            onSelect={onSelect}
          />
        );
      }
    );
  };

  return <Fragment>{renderFields()}</Fragment>;
};
