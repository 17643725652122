import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Table, Modal, Button } from "react-bootstrap";
import { db, functions } from "../../../firebase";
import {
  setDoc,
  getDoc,
  getDocs,
  updateDoc,
  doc,
  collection,
  deleteDoc,
} from "firebase/firestore";
import swal from "sweetalert";
import { notifyError, notifyTopRight } from "../toast/SetToast";
import { useAuth } from "../../../context/AuthContext";
import { httpsCallable } from "firebase/functions";

/// images
import avartar5 from "../../../images/avatar/5.png";
import avartar1 from "../../../images/avatar/1.jpg";

const List = () => {
  const deleteUserByEmail = httpsCallable(functions, "deleteUserByEmail");
  const { currentUser } = useAuth();
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [adminsList, setAdmins] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [goNext, setGoNext] = useState(false);
  // const [goPrev, setGoPrev] = useState(true);
  // const [page, setPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(1);
  // const itemsPerPage = 10;

  const firstnameRef = useRef();
  const lastnameRef = useRef();
  const nodeRef = useRef();
  // const positionRef = useRef();
  const adminDefault = {
    firstname: "",
    lastname: "",
    // email: "",
    node: "",
    // position: "",
    role: "",
    // photoURL: "",
  };
  const tmpAdmin = {
    doc: adminDefault,
    id: "",
  };
  const [currentAdmin, setCurrentAdmin] = useState(tmpAdmin);
  const drop = (adminInfo) => {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="" className="table-dropdown icon-false">
          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect x="0" y="0" width="24" height="24"></rect>
              <circle fill="#000000" cx="5" cy="12" r="2"></circle>
              <circle fill="#000000" cx="12" cy="12" r="2"></circle>
              <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              setCurrentAdmin(adminInfo);
              setEditModal(true);
            }}
          >
            Admin Detail
          </Dropdown.Item>

          <Dropdown.Item
            href="#"
            onClick={() => {
              // refDeleteDoc(bookingInfo);

              swal({
                title: "Enter Password",
                text: "God of Dota2",
                content: {
                  element: "input",
                  attributes: {
                    placeholder: "Type delete password",
                    type: "password",
                  },
                },
              }).then((inputValue) => {
                if (inputValue === "tinker") {
                  swal("Account has been deleted!", {
                    icon: "success",
                  });
                  refDeleteDoc(adminInfo);
                } else {
                  swal("Your password is worng !", {
                    icon: "warning",
                  });
                }
              });
            }}
            className="text-danger"
          >
            Delete
          </Dropdown.Item>
          {/* <Dropdown.Item
            href="#"
            onClick={() => {
              // refDeleteDoc(bookingInfo);
              swal({
                title: "Are you sure to delete ?",
                text: "Once deleted, you will not be able to recover this account !",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  // console.log(adminInfo);
                  refDeleteDoc(adminInfo);
                }
              });
            }}
            className="text-danger"
          >
            Delete
          </Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const chackbox = document.querySelectorAll(".customer_shop_single input");
  const motherChackBox = document.querySelector(".customer_shop input");
  // console.log(document.querySelectorAll(".publish_review input")[0].checked);
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };

  const chack = (i) => (
    <div className={`custom-control custom-checkbox ml-2`}>
      <input
        type="checkbox"
        className="custom-control-input "
        id={`checkAll${i}`}
        required=""
        onClick={() => chackboxFun()}
      />
      <label className="custom-control-label" htmlFor={`checkAll${i}`}></label>
    </div>
  );

  const editmodal = (
    <Modal
      className="fade bd-example-modal-lg"
      show={editModal}
      onHide={() => setEditModal(false)}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>Edit Admin</Modal.Title>
        <Button
          variant=""
          className="close"
          onClick={() => setEditModal(false)}
        >
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className="card-body">
          <div className="row">
            <div className="col-xl-11">
              <div className="form-group row">
                <label
                  className="col-lg-3 col-form-label"
                  htmlFor="val-username"
                >
                  Name
                </label>
                <div className="col-lg-4">
                  <input
                    type="text"
                    className="form-control"
                    id="val-username"
                    name="val-username"
                    placeholder="First Name"
                    // value=""
                    ref={firstnameRef}
                    defaultValue={currentAdmin.firstname}
                  />
                </div>
                <div className="col-lg-4">
                  <input
                    type="text"
                    className="form-control"
                    id="val-username"
                    name="val-username"
                    placeholder="Last Name"
                    ref={lastnameRef}
                    defaultValue={currentAdmin.lastname}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  className="col-lg-3 col-form-label"
                  htmlFor="val-username"
                >
                  Email
                </label>
                <div className="col-lg-4 mt-2">
                  <text>
                    <b>{currentAdmin.email}</b>
                  </text>
                </div>
              </div>

              <div className="form-group row">
                <label
                  className="col-lg-3 col-form-label"
                  htmlFor="val-username"
                >
                  Node
                </label>
                <div className="col-lg-4 mt-2">
                  <text>
                    <b>{currentAdmin.node}</b>
                  </text>
                  {/* <select
                    className="form-control form-control-lg-2"
                    id="inlineFormCustomSelect"
                    ref={nodeRef}
                    defaultValue={currentAdmin.doc.node}
                  >
                    {nodeList.map((value) => (
                      <option
                        value={value.code}
                        selected={
                          currentAdmin.doc.node == value.code ? "selected" : ""
                        }
                      >
                        {value.code}
                      </option>
                    ))}
                  </select> */}
                </div>
              </div>
              <div className="form-group row">
                <label
                  className="col-lg-3 col-form-label"
                  htmlFor="val-username"
                >
                  Role
                </label>
                <div className="col-lg-4 mt-2">
                  <text>
                    <b>{currentAdmin.role}</b>
                  </text>
                </div>
              </div>

              <div className="form-group row mt-5">
                <label className="col-lg-3 col-form-label"> </label>
                <div className="col-lg-4"> </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger light" onClick={() => setEditModal(false)}>
          Cancel
        </Button>
        <Button
          variant=""
          type="button"
          onClick={() => handleEdit()}
          className="btn btn-primary"
        >
          Edit
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const refDeleteDoc = async (adminInfo) => {
    // const userRecord = await adminUser.auth().getUserByEmail(adminInfo.doc.email);
    // await adminUser.auth().deleteUser(userRecord.uid);
    var email = adminInfo.email;
    console.log(adminInfo);
    deleteUserByEmail({ email: email });

    const refDelete = doc(db, "admins", adminInfo.displayName);
    await deleteDoc(refDelete)
      .then(async (res) => {
        // notifyTopRight("Delete Success!", res);
        swal("Your admin has been deleted!", {
          icon: "success",
        });

        // refresh useEffect
        getAdminsList();
      })
      .catch((err) => {
        notifyError("Detele Error ", err);
      });
  };
  const handleEdit = async () => {
    const docRef = doc(db, "admins", currentAdmin.id);

    let update = {
      ...currentAdmin,
    };
    update.firstname = firstnameRef.current.value;
    update.lastname = lastnameRef.current.value;
    // update.position = positionRef.current.value;
    // update.node = nodeRef.current.value;

    await updateDoc(docRef, {
      ...update,
    });

    notifyTopRight("Update success");
    setEditModal(false);
    getAdminsList();
    return;
  };
  const getAdminsList = async () => {
    let admins = [];
    const docSnap = await getDocs(collection(db, "admins"));
    docSnap.forEach((doc) => {
      admins.push({ ...doc.data(), displayName: doc.id });
    });
    if (currentUser.roles == "super_admin") {
      setAdmins(admins);
    } else {
      const currentAdmin = await getDoc(
        doc(db, "admins", currentUser.displayName)
      );
      const filterAdmins = admins.filter((admin) => {
        return admin.node == currentAdmin.data().node;
      });
      // console.log(adminsFilter)
      setAdmins(filterAdmins);
    }
  };
  useEffect(async () => {
    await getAdminsList().then(() => {
      setIsLoading(false);
      console.log(currentUser);
      // console.log(adminsList);
    });
  }, []);
  return (
    <Fragment>
      {!isLoading && (
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table mb-0 table-striped">
                    <thead>
                      <tr>
                        {/* <th className="customer_shop">
                        <div className="custom-control custom-checkbox mx-2">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="checkAll"
                            onClick={() => chackboxFun("all")}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                      </th> */}
                        <th>Name</th>
                        <th>Email</th>
                        <th>Node</th>
                        <th>Position</th>
                        <th>Role</th>
                        {currentUser.roles.some((role) =>
                          ["super_admin", "admin"].includes(role)
                        ) && <th>Options</th>}
                      </tr>
                    </thead>
                    {/* <tbody id="customers">{userList}</tbody> */}
                    <tbody id="customers">
                      {adminsList.map((adminElement, index) => {
                        return (
                          <tr className="btn-reveal-trigger">
                            {/* <td className="py-2 customer_shop_single">
                            {chack(index)}
                          </td> */}
                            <td className="py-2">
                              <Link
                                to="#"
                                onClick={() => {
                                  setCurrentAdmin(adminElement);
                                  setEditModal(true);
                                }}
                              >
                                <div className="media d-flex align-items-center">
                                  <div className="avatar avatar-xl mr-2">
                                    <img
                                      className="rounded-circle img-fluid"
                                      src={avartar5}
                                      width="30"
                                      alt=""
                                    />
                                  </div>
                                  <div className="media-body">
                                    <h5 className="mb-0 fs--1">
                                      {adminElement["firstname"]}{" "}
                                      {adminElement["lastname"]}
                                    </h5>
                                  </div>
                                </div>
                              </Link>
                            </td>
                            <td className="py-2">{adminElement["email"]}</td>
                            <td className="py-2">{adminElement["node"]}</td>
                            <td className="py-2">{adminElement["position"]}</td>
                            <td className="py-2">{adminElement["role"]}</td>
                            {currentUser.roles.some((role) =>
                              ["super_admin", "admin"].includes(role)
                            ) && <td className="py-2">{drop(adminElement)}</td>}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div>{editmodal}</div>
        </div>
      )}
      {/* <div>{deletemodal}</div> */}
    </Fragment>
  );
};

export default List;
