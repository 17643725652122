import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../../context/AuthContext";
import { functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";

import { EditForm } from "./EditForm";

const ProductDetail = (props) => {
  const getSignedUrl = httpsCallable(functions, "getSignedUrl");
  const [isLoading, setIsLoading] = useState(false);
  const [stateEdit, setStateEdit] = useState(true);

  const [isImageLoding, setIsImageLoding] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);

  const [fileNames, setFilenames] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const inputRef = useRef({
    serialNumber: null,
    brand: null,
    model: null,
    description: null,
    type: null,
    vendor: null,
    deviceNo: null,
    lotNo: null,
    incomingDate: null,
    note: null,
  });
  // console.log(props.cpap);
  const handleChange = (name) => (e) => {
    if (name === "cpapImage") {
      for (let i = 0; i < e.target.files.length; i++) {
        const f = e.target.files[i];
        setSelectedFiles((prevState) => [...prevState, f]);
        setFilenames((prevState) => [...prevState, f.name]);
      }
    } else {
      inputRef.current[name] = e.target.value;
    }
  };

  const handleOnViewFile = (file) => {
    setIsImageLoding(true);
    getSignedUrl({
      folder: "cpapimages",
      hn: props.cpap.serialNumber,
      id: props.cpap.id,
      filename: file,
    })
      .then((result) => {
        console.log("url", result.data);
        setFileUrl(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setIsImageLoding(false);
    setCreateModal(true);
  };

  const handleHideView = () => {
    setCreateModal(false);
    setFileUrl(null);
  };

  const submitHandle = (e) => {
    e.preventDefault();

    const formData = {
      serialNumber: inputRef.current.serialNumber
        ? inputRef.current.serialNumber
        : props.cpap.serialNumber,
      brand: inputRef.current.brand ? inputRef.current.brand : props.cpap.brand,
      model: inputRef.current.model ? inputRef.current.model : props.cpap.model,
      description:
        inputRef.current.description != undefined
          ? inputRef.current.description
          : props.cpap.description,
      type: inputRef.current.type ? inputRef.current.type : props.cpap.type,
      vendor: inputRef.current.vendor
        ? inputRef.current.vendor
        : props.cpap.vendor,
      deviceNo:
        inputRef.current.deviceNo != "" ? inputRef.current.deviceNo : "---",

      lotNo:
        inputRef.current.lotNo != undefined
          ? inputRef.current.lotNo
          : props.cpap.lotNo,
      incomingDate: inputRef.current.incomingDate
        ? new Date(inputRef.current.incomingDate)
        : props.cpap.incomingDate,
      note:
        inputRef.current.note != undefined
          ? inputRef.current.note
          : props.cpap.note,
      updatedAt: new Date(),
    };

    // console.log("inputRef.current.deviceNo", inputRef.current.deviceNo);
    // console.log("deviceNo", formData.deviceNo);
    console.log("deviceNo", formData.deviceNo);

    props.onEdit({
      ...formData,
      id: props.cpap.id,
      images: selectedFiles.length === 0 ? [] : selectedFiles,
      // images: selectedFiles,
    });
    setStateEdit(!stateEdit);
  };
  return (
    <Fragment>
      <div className="form-head d-flex mb-3 mb-lg-5 align-items-start">
        <Button
          // onClick={() => {setStateEdit(!stateEdit)}}
          onClick={() => {
            // if (stateEdit == false) {
            //   Object.keys(props.cpap).forEach((key) => {
            //     try {
            //       CpapRef.current[key].value = CpapInfo[key];
            //     } catch (err) {
            //       CpapRef.current[key] = CpapInfo[key];
            //     }
            //   });
            //   setType(CpapInfo.Type);
            //   setBrand(CpapInfo.Brand);
            // }
            setStateEdit(!stateEdit);
          }}
          className={stateEdit ? "btn btn-dark" : "btn btn-blue"}
        >
          <i className="flaticon-381-clock mr-2"></i>Edit
        </Button>
      </div>

      {!isImageLoding && (
        <Modal
          className="fade bd-example-modal-lg"
          show={createModal}
          onHide={handleHideView}
          size="xl"
        >
          <Modal.Header>
            <Modal.Title>Note</Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div
              className="card-body mx-0 p-0 col-lg-12"
              style={{ height: "85vh" }}
            >
              <iframe
                className="col-lg-12 m-0 p-0"
                src={fileUrl + "#toolbar=0"}
                width="100%"
                height="100%"
              ></iframe>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      )}

      {!isLoading && (
        <div className="row">
          <div className="col-lg-12">
            <div className="form-validation">
              <form
                className="form-valide"
                action="#"
                method="post"
                onSubmit={submitHandle}
              >
                <div className="row">
                  <div className="col-xl-6">
                    <EditForm
                      onChange={handleChange}
                      initailValues={props.cpap}
                      onEdit={stateEdit}
                      fileNames={fileNames}
                      defaultfiles={props.cpap.filename}
                    />
                    {!stateEdit && (
                      <div className="form-group row">
                        <div className="col-lg-8 ml-auto">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ProductDetail;
