import React, { Fragment, useState, useRef, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import { EditForm } from "./EditForm";

const DetailModal = (props) => {
  const getSignedUrl = httpsCallable(functions, "getSignedUrl");

  const [isLoding, setIsLoading] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileNames, setFilenames] = useState([]);
  const inputRef = useRef({
    serialNumber: null,
    brand: null,
    model: null,
    description: null,
    type: null,
    vendor: null,
    deviceNo: null,
    lotNo: null,
    incomingDate: null,
    note: null,
    status: null,
    sales: null,
  });

  const handleChange = (name) => (e) => {
    if (name === "cpapImage") {
      for (let i = 0; i < e.target.files.length; i++) {
        const f = e.target.files[i];
        setSelectedFiles((prevState) => [...prevState, f]);
        setFilenames((prevState) => [...prevState, f.name]);
      }
    } else {
      inputRef.current[name] = e.target.value;
    }
  };
  const handleOnHideModal = () => {
    props.hide();
  };

  const handleOnViewFile = (file) => {
    setIsLoading(true);
    getSignedUrl({
      folder: "cpapimages",
      hn: props.cpap.serialNumber,
      id: props.cpap.id,
      filename: file,
    })
      .then((result) => {
        console.log("url", result.data);
        setFileUrl(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
    setCreateModal(true);
  };

  const handleHideView = () => {
    setCreateModal(false);
    setFileUrl(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      serialNumber: inputRef.current.serialNumber
        ? inputRef.current.serialNumber
        : props.cpap.serialNumber,
      brand: inputRef.current.brand ? inputRef.current.brand : props.cpap.brand,
      model: inputRef.current.model ? inputRef.current.model : props.cpap.model,
      description:
        inputRef.current.description != undefined
          ? inputRef.current.description
          : props.cpap.description,
      type: inputRef.current.type ? inputRef.current.type : props.cpap.type,
      vendor: inputRef.current.vendor
        ? inputRef.current.vendor
        : props.cpap.vendor,
      deviceNo:
        inputRef.current.deviceNo != "" ? inputRef.current.deviceNo : "---",
      // deviceNo:
      //   inputRef.current.deviceNo != ""
      //     ? inputRef.current.deviceNo
      //     : props.cpap.deviceNo,
      lotNo:
        inputRef.current.lotNo != undefined
          ? inputRef.current.lotNo
          : props.cpap.lotNo,
      incomingDate: inputRef.current.incomingDate
        ? new Date(inputRef.current.incomingDate)
        : props.cpap.incomingDate,
      note:
        inputRef.current.note != undefined
          ? inputRef.current.note
          : props.cpap.note,
      status:
        props.cpap.status == "ขาย"
          ? props.cpap.status
          : inputRef.current.status != undefined
          ? inputRef.current.status
          : props.cpap.status,

      sales:
        props.cpap.status == "ว่าง"
          ? ""
          : inputRef.current.status == "ว่าง"
          ? ""
          : inputRef.current.sales != undefined
          ? inputRef.current.sales
          : props.cpap.sales,
      updatedAt: new Date(),
      images: fileNames.length !== 0 ? [] : props.cpap.images,
    };

    props.onEditSubmit(props.cpap.id, formData, selectedFiles);
    setFilenames([]);
    setSelectedFiles([]);
    props.hide();
  };

  return (
    <Fragment>
      {!isLoding && (
        <Modal
          className="fade bd-example-modal-lg"
          show={createModal}
          onHide={handleHideView}
          size="xl"
        >
          <Modal.Header>
            <Modal.Title>Note</Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div
              className="card-body mx-0 p-0 col-lg-12"
              style={{ height: "85vh" }}
            >
              <iframe
                className="col-lg-12 m-0 p-0"
                src={fileUrl + "#toolbar=0"}
                width="100%"
                height="100%"
              ></iframe>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      )}
      <Modal
        className="fade bd-example-modal-lg"
        show={props.show}
        onHide={handleOnHideModal}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Edit Product Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditForm
            onChange={handleChange}
            initailValues={props.cpap}
            fileNames={fileNames}
            defaultfiles={props.cpap.images}
            onViewFile={handleOnViewFile}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
        <div className="d-flex justify-content-center mb-3">
          <Button className="sm" onClick={handleSubmit}>
            Save
          </Button>
          <Button
            className="sm ml-2"
            variant="light"
            onClick={handleOnHideModal}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
};

export default DetailModal;
