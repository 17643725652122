import { db, storage } from "../firebase";
import {
  setDoc,
  getDocs,
  getDoc,
  collection,
  updateDoc,
  doc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { ref, uploadBytes, deleteObject, listAll } from "firebase/storage";
import uuid from "uuid";
// ADD CPAP
export const addCpap = (cpap) => ({
  type: "ADD_CPAP",
  cpap,
});

export const startAddCpap = (cpapData = {}, files) => {
  return async (dispatch) => {
    const {
      serialNumber = null,
      brand = "",
      model = "",
      description = "",
      type = "",
      vendor = "",
      deviceNo = "",
      lotNo = "",
      incomingDate = 0,
      status = "",
      note = "",
      createdBy = "",
      updatedAt = 0,
      createdAt = 0,
    } = cpapData;
    const cpap = {
      serialNumber,
      brand,
      model,
      description,
      type,
      vendor,
      deviceNo,
      lotNo,
      incomingDate,
      status,
      note,
      createdBy,
      updatedAt,
      createdAt,
    };
    // console.log("cpap =>", cpap);

    const docRef = doc(collection(db, "products"));
    await setDoc(docRef, cpap)
      .then(() => {
        //upload cpap image
        const promises = [];
        const f = [];
        files.map((file) => {
          const storageRef = ref(
            storage,
            `cpapimages/${cpap.serialNumber}/${docRef.id}/${file.name}`
          );
          f.push(file.name);
          const uploadTask = uploadBytes(storageRef, file);
          promises.push(uploadTask);
        });
        Promise.all(promises);

        //update products doc
        updateDoc(doc(db, "products", docRef.id), {
          id: docRef.id,
          images: f,
        });
        dispatch(
          addCpap({
            id: docRef.id,
            ...cpap,
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

// REMOVE CPAP
export const removeCpap = ({ id } = {}) => ({
  type: "REMOVE_CPAP",
  id,
});

export const startRemoveCpap = ({ id }) => {
  return async (dispatch) => {
    const docRef = doc(db, "products", id);
    return await deleteDoc(docRef).then(() => {
      dispatch(removeCpap({ id }));
    });
  };
};

// EDIT CPAP
export const editCpap = (id, updates) => ({
  type: "EDIT_CPAP",
  id,
  updates,
});

export const startEditCpap = (id, updates, files) => {
  return async (dispatch) => {
    const docRef = doc(db, "products", id);

    console.log("startEditCpap", updates);
    const f = [];

    //delete file
    const storageRef = ref(storage, `cpapimages/${updates.serialNumber}/${id}`);
    listAll(storageRef).then((res) => {
      const removeRef = res.items.filter(
        (itemRef) => !updates.images.includes(itemRef.name)
      );
      removeRef.forEach((res) => {
        const storageRef = ref(
          storage,
          `cpapimages/${updates.serialNumber}/${res.name}`
        );
        deleteObject(storageRef, res.name);
      });
    });

    //upload file
    if (files != null) {
      const promises = [];
      files.map((file) => {
        const storageRef = ref(
          storage,
          `cpapimages/${updates.serialNumber}/${docRef.id}/${file.name}`
        );
        f.push(...updates.images, file.name);
        const uploadTask = uploadBytes(storageRef, file);
        promises.push(uploadTask);
      });
      Promise.all(promises);
    }

    return await updateDoc(docRef, {
      ...updates,
      images: f.length !== 0 ? f : updates.images ? updates.images : [],
    }).then(() => {
      dispatch(
        editCpap(id, {
          ...updates,
          images: f.length === 0 ? updates.images : f,
        })
      );
    });
  };
};

// SET CPAP
export const setCpap = (cpap) => ({
  type: "SET_CPAP",
  cpap,
});
export const startSetCpap = (id) => {
  return async (dispatch) => {
    const docRef = doc(db, "products", id);

    return await getDoc(docRef).then((snapshot) => {
      dispatch(setCpap(snapshot.data()));
      console.log(snapshot.data());
    });
  };
};

// SET CPAPS
export const setCpaps = (cpaps) => ({
  type: "SET_CPAPS",
  cpaps,
});
export const startSetCpaps = () => {
  return async (dispatch) => {
    const docRef = collection(db, "products");
    return await getDocs(docRef).then((snapshot) => {
      const cpaps = [];
      snapshot.forEach(async (childSnapshot) => {
        cpaps.push({
          id: childSnapshot.id,
          ...childSnapshot.data(),
        });
      });

      dispatch(setCpaps(cpaps));
    });
  };
};
