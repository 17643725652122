import React, { Fragment, useRef } from "react";
import _ from "lodash";

import RegisterFields from "./RegisterFields";
import orderFormFields from "./orderFormFields";

export const OrderForm = (props) => {
  const inputRef = useRef({});

  const renderFields = () => {
    return _.map(
      orderFormFields,
      ({ label, name, type, options, required, disabled, description }) => {
        return (
          <RegisterFields
            name={name}
            label={label}
            type={type}
            value={
              name !== "receiptNo"
                ? inputRef.current.name
                : props.saleOrderNumber
            }
            fileNames={props.fileNames}
            options={options}
            required={required}
            onChange={props.onChange(name)}
            disabled={disabled}
            description={description}
          />
        );
      }
    );
  };

  return <Fragment>{renderFields()}</Fragment>;
};
