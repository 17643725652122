import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import ProductItem from "./ProductItem";
import selectCpaps from "../../../selectors/cpaps";
import DetailModal from "./DetailModal";
import { db } from "../../../firebase";
import {
  doc,
  onSnapshot,
  getDocs,
  getDoc,
  orderBy,
  limit,
  where,
  query,
  collection,
  and,
  or,
} from "firebase/firestore";
import {
  startSetCpaps,
  startEditCpap,
  startRemoveCpap,
} from "../../../actions/cpaps";
import { Dropdown, Button, Col, Table, Card, Row } from "react-bootstrap";
import { useAuth } from "../../../context/AuthContext";
import PageTitle from "../../layouts/PageTitle";
// import orderFormFields from "./orderFormFields";
import formFields from "./formFields";
import orderFormFields from "./orderFormFields";
import { usePagination } from "../../../hook/usePagination";

const ProductList = (props) => {
  const { currentUser } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState({});
  const [searchItem, setSearchItem] = useState("");
  const searchRef = useRef(null);

  const itemPerPage = 50;

  let QueryFilterConstraint = [];
  let queryRef = query(
    collection(db, "products"),
    orderBy("status", "desc"),
    orderBy("createdAt", "desc")
  );

  if (searchItem !== "") {
    QueryFilterConstraint.push(
      where("serialNumber", ">=", searchItem),
      where("serialNumber", "<=", searchItem + "\uf8ff")
    );

    queryRef = query(
      collection(db, "products"),
      and(...QueryFilterConstraint),
      orderBy("status", "desc"),
      orderBy("createdAt", "desc")
    );
  }

  const {
    items: productList,
    isLoading,
    isStart,
    isEnd,
    getPrev,
    getNext,
    totalItems,
    totalPages,
    currentPage,
  } = usePagination(queryRef, { limit: itemPerPage });

  // console.log(productList);

  const handleOnEdit = (id) => {
    const data = props.cpaps.find((c) => c.id === id);

    const ref = collection(db, `products/${data.id}/cpapfile`);
    let queryHandler = query(ref, where("title", "==", "CPAP Image"));

    console.log("edit cpap data", data);

    setEdit(data);
    setShowModal(true);
  };

  const handleOnHide = (sn) => {
    setShowModal(false);
  };

  const handleOnDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        props.startRemoveCpap({ id });
        swal(`Poof! Your file has been deleted!`, {
          icon: "success",
        });
      } else {
        swal("Canceled");
      }
    });
  };

  const onSearchChange = () => {
    setSearchItem(searchRef.current.value);
  };

  const getAvailableCPAP = (cpaps, isReserve, model, sale) => {
    const cpapAvailableList = cpaps.filter((cpap) => {
      let isAvailable;
      if (isReserve == true) {
        isAvailable = cpap.status === "จอง";
      } else {
        isAvailable = cpap.status === "ว่าง";
      }

      // If brand is provided, add it as a condition
      if (model) {
        isAvailable = isAvailable && cpap.model === model;
      }

      // If sale is provided, add it as a condition
      if (sale) {
        isAvailable = isAvailable && cpap.sales === sale;
      }
      return isAvailable;
    });
    return cpapAvailableList;
  };

  useEffect(() => {
    props.startSetCpaps();
    // setStateprops.cpaps
  }, []);
  // console.log("Reserv: ",getAvailableCPAP(props.cpaps, true, "XT-Auto", null))
  return (
    <Fragment>
      <PageTitle activeMenu="Product List" motherMenu="Sales" />
      <DetailModal
        show={showModal}
        hide={handleOnHide}
        cpap={edit}
        onEditSubmit={(id, updates, files) =>
          props.startEditCpap(id, updates, files)
        }
      />
      <div className="col-xl-12 col-xxl-12">
        <div className="row">
          <Link
            className="col-xl-3 col-lg-3 col-sm-3"
            to="#"
            // onClick={() => console.log("Kokkak")}
          >
            <div className="widget-stat card bg-primary pt-4 pb-4">
              <Col>
                <h3 className="text-white text-center">{`เครื่องว่าง ${
                  getAvailableCPAP(props.cpaps).length
                }`}</h3>
              </Col>
              {/* <Col className="justify-content-center">
                {formFields[2].options.map((model, index) => {
                  return (
                    <div
                      key={index}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <h4 className="text-white text-center">{model}</h4>
                      <h4 className="text-white text-center">
                        {
                          getAvailableCPAP(props.cpaps, null, model, null)
                            .length
                        }
                      </h4>
                    </div>
                  );
                })}
              </Col> */}
              <Col className="justify-content-center">
                <Table className="table table-borderless text-white">
                  <thead>
                    <tr>
                      <th style={{ padding: "5px" }}></th>
                      <th style={{ padding: "5px" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {formFields[2].options.map((model, modelIndex) => (
                      <tr key={modelIndex}>
                        <td style={{ padding: "5px" }}>{model}</td>
                        <td style={{ padding: "5px", textAlign: "center" }}>
                          {" "}
                          {getAvailableCPAP(props.cpaps, null, model, null)
                            .length == 0
                            ? ""
                            : getAvailableCPAP(props.cpaps, null, model, null)
                                .length}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </div>
          </Link>
          <Link className="col-xl-9 col-lg-9 col-sm-9" to="#">
            <div className="widget-stat card bg-info pt-4 pb-4">
              <Col>
                <h3 className="text-white text-center pb-4">{`เครื่องจอง ${
                  getAvailableCPAP(props.cpaps, true, null, null).length
                }`}</h3>
              </Col>
              <Col className="justify-content-center">
                <Table className="table table-borderless text-white">
                  <thead>
                    <tr>
                      <th style={{ padding: "5px" }}></th>
                      {orderFormFields[14].options.map((sale, index) => (
                        <th
                          key={index}
                          style={{ padding: "5px", textAlign: "center" }}
                        >
                          {sale}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {formFields[2].options.map((model, modelIndex) => (
                      <tr key={modelIndex}>
                        <td style={{ padding: "5px" }}>{model}</td>
                        {orderFormFields[14].options.map((sale, saleIndex) => (
                          <td
                            key={saleIndex}
                            style={{ padding: "5px", textAlign: "center" }}
                          >
                            {getAvailableCPAP(props.cpaps, true, model, sale)
                              .length == 0
                              ? ""
                              : getAvailableCPAP(props.cpaps, true, model, sale)
                                  .length}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </div>
          </Link>
        </div>
      </div>

      <div className="form-head d-flex mb-3 mb-lg-5 align-items-start">
        <Link
          to="/product-register"
          className="btn btn-danger"
          data-toggle="tooltip"
          data-placement="top"
          title="Product Registration"
        >
          + New Product
        </Link>
        <div className="input-group search-area ml-auto d-inline-flex">
          <input
            type="text"
            className="form-control"
            placeholder="Search here"
            ref={searchRef}
            onChange={onSearchChange}
          />
          <div className="input-group-append">
            <span className="input-group-text c-pointer">
              <i className="flaticon-381-search-2"></i>
            </span>
          </div>
        </div>
      </div>
      <ProductItem
        cpaps={productList}
        onEdit={handleOnEdit}
        onDelete={handleOnDelete}
        onNext={!isEnd && getNext}
        onPrev={!isStart && getPrev}
        totalItems={totalItems}
        totalPages={totalPages}
        currentPage={currentPage}
        itemsPerPage={itemPerPage}
      />
    </Fragment>
  );
};

const mapStateToProps = (state, props) => ({
  cpaps: selectCpaps(state.cpaps, { text: "" }),
});

const mapDispatchToProps = (dispatch) => ({
  startSetCpaps: () => dispatch(startSetCpaps()),
  startEditCpap: (id, updates, files) =>
    dispatch(startEditCpap(id, updates, files)),
  startRemoveCpap: (id) => dispatch(startRemoveCpap(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
