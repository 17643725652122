import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
export default ({
  name,
  label,
  type,
  value,
  initailValue,
  onChange,
  options,
  disabled,
  required,
  description,
  fileNames,
  defaultfiles,
  onViewFile,
}) => {
  return (
    <div className="form-group row m-1">
      <label label className="col-lg-4 col-form-label">
        {label}
        {required && <span className="text-danger">*</span>}
      </label>

      <div className="col-lg-6">
        {type === "select" ? (
          <div>
            <select
              key={name}
              className="form-control"
              defaultValue={initailValue}
              onChange={onChange}
              disabled={disabled}
            >
              {!initailValue && <option value={null}>please-select</option>}

              {options.map((opt) => (
                <option value={opt}>{opt}</option>
              ))}
            </select>
            <span className="text-danger">
              <small>{description}</small>
            </span>
          </div>
        ) : type === "text" ? (
          <div>
            <input
              key={name}
              className="form-control"
              type={type}
              name={name}
              value={value}
              onChange={onChange}
              defaultValue={initailValue}
              disabled={disabled}
              required={required}
            />
            <small>{description}</small>
          </div>
        ) : type === "date" || type === "time" ? (
          <>
            <input
              key={name}
              className="form-control"
              placeholder="dd-mm-yyyy"
              type={type}
              name={name}
              value={value}
              onChange={onChange}
              defaultValue={initailValue}
              disabled={disabled}
              required={required}
            />
          </>
        ) : type === "textarea" ? (
          <textarea
            key={name}
            className="form-control"
            type={type}
            name={name}
            value={value}
            rows={"3"}
            onChange={onChange}
            defaultValue={initailValue}
            disabled={disabled}
            required={required}
          />
        ) : type === "file" ? (
          <div className="basic-form custom_file_input">
            <div className="input-group">
              <div className="custom-file">
                <input
                  key={name}
                  type="file"
                  multiple
                  className="custom-file-input"
                  onChange={onChange}
                  disabled={disabled}
                />
                {fileNames.length > 0 ? (
                  <label className="custom-file-label">{fileNames}</label>
                ) : (
                  <label className="custom-file-label">choose file</label>
                )}
              </div>
            </div>
            {defaultfiles && (
              <span className="text-info">
                <Link
                  key={defaultfiles}
                  className="text-muted"
                  to="#"
                  onClick={() => onViewFile(defaultfiles)}
                >
                  <small>{defaultfiles}</small>
                </Link>
              </span>
            )}
          </div>
        ) : type === "radio" ? (
          <div className="basic-form">
            {options.map((opt) => (
              <div key={opt} className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name={name}
                  value={opt}
                  onChange={onChange}
                  defaultChecked={initailValue === opt}
                />
                <label className="form-check-label">{opt}</label>
              </div>
            ))}
            <span className="text-danger">
              <small>{description}</small>
            </span>
          </div>
        ) : (
          {}
        )}
      </div>
    </div>
  );
};
