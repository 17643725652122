import React, { Fragment, useState, useRef, useEffect } from "react";
import _ from "lodash";
import RegisterFields from "./RegisterFields";
import formFields from "./formFields";

export const RegisterForm = ({
  onChange,
  initailValues,
  fileNames,
  startDate,
  endDate,
}) => {
  // const inputRef = useRef({});
  const inputRef = useRef({
    serialNumber: "",
    brand: "",
    model: "",
    description: "",
    type: "",
    vendor: "",
    deviceNo: "",
    lotNo: "",
    incomingDate: null,
    note: "",
  });

  console.log(startDate);
  console.log(endDate);

  const renderFields = () => {
    return _.map(
      formFields,
      ({ label, name, type, options, required, description }) => {
        return (
          <RegisterFields
            name={name}
            label={label}
            type={type}
            value={inputRef.current.name}
            options={options}
            required={required}
            onChange={onChange(name)}
            description={description}
            fileNames={fileNames}
            startDate={startDate}
            endDate={endDate}
          />
        );
      }
    );
  };

  useEffect(() => {
    inputRef.current = "";
  }, []);

  return <Fragment>{renderFields()}</Fragment>;
};
