import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../../context/AuthContext";

import { notifyTopRight, notifyWarning, notifyError } from "../toast/SetToast";
import Toast from "../toast/SetToast";
import { Badge, Button, Row, Col, Card, Tabs, Tab, Nav } from "react-bootstrap";
import ProductDetail from "./ProductDetail";
import { startEditCpap, startSetCpap } from "../../../actions/cpaps";
import selectCpaps from "../../../selectors/cpaps";
import ProductActivity from "./ProductActivity";
import ProductFiles from "./ProductFiles/ProductFiles";

const ProductPage = (props) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [showCpapStatus, setShowCpapStatus] = useState("");
  const [showPage, setShowPage] = useState(0);

  const onSubmit = (updates) => {
    props
      .startEditCpap(updates.id, updates, updates.images)
      .then(() => {
        notifyTopRight("Updated !");
      })
      .catch((error) => {
        notifyError(error);
      });
  };

  const tabData = [
    {
      name: "Detail",
      icon: "user",
      content: function (props, setState) {
        return <ProductDetail cpap={props} onEdit={setState} />;
      },
    },
    {
      name: "Activity",
      icon: "clipboard",
      content: function (props, setState) {
        return <ProductActivity cpap={props} />;
      },
    },
    {
      name: "Files",
      icon: "clipboard",
      content: function (props, setState) {
        return <ProductFiles cpap={props} />;
      },
    },
  ];
  useEffect(() => {
    setIsLoading(false);
  }, []);
  return (
    <>
      <PageTitle
        activeMenu="Product Detail"
        motherMenu="Sales"
        motherLink="/product-list"
      />
      <Toast />
      {isLoading ? (
        <></>
      ) : (
        <Card>
          <Card.Header>
            <Card.Title>Product Information</Card.Title>
            <Card.Title>
              {props.cpap[0].status === "ว่าง" ? (
                <Badge variant="info dark">{props.cpap[0].status}</Badge>
              ) : props.cpap[0].status === "จอง" ? (
                <Badge variant="warning dark">{props.cpap[0].status}</Badge>
              ) : props.cpap[0].status === "ยกเลิก" ? (
                <Badge variant="danger dark">{props.cpap[0].status}</Badge>
              ) : (
                <Badge variant="success light">{props.cpap[0].status}</Badge>
              )}
              {/* <Badge variant="success light">{props.cpap[0].status}</Badge> */}
            </Card.Title>
          </Card.Header>
          <Card.Body>
            {/* <!-- Nav tabs --> */}
            <div className="default-tab">
              <Tab.Container
                defaultActiveKey={tabData[showPage].name.toLowerCase()}
              >
                <Nav as="ul" className="nav-tabs">
                  {tabData.map((data, i) => (
                    <Nav.Item as="li" key={i}>
                      <Nav.Link eventKey={data.name.toLowerCase()}>
                        <i className={`la la-${data.icon} mr-2`} />
                        {data.name}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
                <Tab.Content className="pt-4">
                  {tabData.map((data, i) => (
                    <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                      {data.content(props.cpap[0], onSubmit)}
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Tab.Container>
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

const mapStateToProps = (state, props) => ({
  cpap: selectCpaps(state.cpaps, { text: props.match.params.id }),
});

const mapDispatchToProps = (dispatch) => ({
  startEditCpap: (id, updates, files) =>
    dispatch(startEditCpap(id, updates, files)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);
